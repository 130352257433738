import React from "react";
// component
import Inclusion from "../Home/Inclusion/Inclusion";
import SuccessStories from "../Home/SuccessStories/SuccessStories";
import QuizBanner from "./QuizBanner";
import QuizBookWebinar from "./QuizBookWebinar";

const QuizResult = props => {
  const { user, quizResult } = props;

  return (
    <>
      <QuizBanner user={user} quizResult={quizResult} />
      <QuizBookWebinar />
      <SuccessStories
        carouselId="diabetiesQuizSuccessStories"
        bgColor={"bgLightpink"}
        titleText="Numerous people are reversing diabetes with Whole Body Digital Twin™"
      />
      <Inclusion />
      <div
        className="bgLightgray"
        style={{
          fontSize: "13px",
          fontWeight: "400",
          color: "#4C4C4C",
          padding: "0px 18px 18px 18px",
        }}
      >
        <p>
          *T&C: For sustained diabetes reversal we recommend adhering to the
          program for at least 52 weeks for a chance at Diabetes-free life.
        </p>
      </div>
    </>
  );
};

export default QuizResult;
