import React from "react";

//images
import { StaticImage } from "gatsby-plugin-image";

//css
import * as styles from "./DiabetesQuiz.module.css";
import { navigate } from "gatsby";
import { sendToCT } from "../../services/Clevertap";

const QuizBookWebinar = props => {
  const navigateToWebinar = () => {
    sendToCT("diabates_quiz_result_CTA", {
      action: "user clicks on webianr CTA",
    });
    navigate("/reversal-webinar");
  };

  return (
    <div className="bgLightgray">
      <div className="container">
        <div className="row">
          <div className={`col-12  ${styles.webiCon}`}>
            <div className="d-flex justify-content-start justify-content-md-center">
              <span className="gradientText">Webinar on</span>
            </div>
          </div>
          <div className={`col-12  ${styles.webiConn}`}>
            <div className="d-flex justify-content-start justify-content-md-center">
              <span className={styles.webiTxt1}>Beat Diabetes</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row ps-3 pe-3 pe-md-4">
              <div className={`col-12 pb-4 ${styles.webDrCon}`}>
                <div className={`col-12 mb-md-1  ${styles.webititle}`}>
                  <span className={styles.webiTit}> Session with </span>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className={styles.dr1Img}>
                      <StaticImage
                        src={"../../images/quiz/webinarDr1.png"}
                        alt="webinar Dr"
                        placeholder="blurred"
                      />
                    </div>
                  </div>
                  <div className="col-7">
                    <div className={styles.drName}>Dr. Rohit Warrier</div>
                    <div className={styles.designation}>
                      MBBS, MS, MSc Diabetology (UK)
                    </div>
                    <div className={styles.yoe}>10+ years of experience</div>
                    <div className={styles.treated}>
                      10,000+ diabetes treated
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-4">
                    <div className={styles.dr1Img}>
                      <StaticImage
                        src={"../../images/quiz/webinarDr2.png"}
                        alt="webinar Dr"
                        placeholder="blurred"
                      />
                    </div>
                  </div>
                  <div className="col-7">
                    <div className={styles.drName}>Nitish Saxena</div>
                    <div className={styles.designationSecond}>
                      Associate Director Twin Health
                    </div>
                    {/* <div className={styles.yoe}>10+ years of experience</div> */}
                    <div className={styles.treated2}>
                      5,000+ Diabetics Consulted
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 pb-5 d-block d-lg-none d-xl-none d-xxl-none">
            <div className={`justify-content-center ${styles.btnCon}`}>
              <div
                role="presentation"
                onClick={navigateToWebinar}
                className="d-flex gradientBtnWithArrow pt-2 pb-2"
              >
                <span className={`pt-3 pb-3 ${styles.priceTxt}`}>
                  {" Register @ "}
                  <strike className={styles.strikRs}>
                    {" "}
                    &#2352;{" 500  "}
                  </strike>{" "}
                  &#2352;{" 79 "}
                </span>
                <span
                  className={`mediumIcon icon-arrow-forward ${styles.whiteFontArrow}`}
                />
              </div>
            </div>
            <div className="text-center">
              <span className={styles.Hurry}>Hurry Up! Limited seats</span>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className={`row px-4 px-md-5 ${styles.webDr2Con}`}>
              <div className={`col-12 ps-0  ${styles.webititle2}`}>
                <div className="d-flex justify-content-md-start justify-content-center">
                  <span className={styles.webiTit2}>
                    {" "}
                    Limited period benefits{" "}
                  </span>
                </div>
              </div>
              <div className={`col-12 ${styles.webDetailsCon}`}>
                <div className="row pe-0">
                  <div className={`col-8`}>
                    <div className={styles.title1}>
                      Interactive Q&A Sessions
                    </div>
                    <div className={styles.title2}>With senior doctors</div>
                  </div>
                  <div className="col-4 d-flex justify-content-end align-items-end pe-0">
                    <div className={styles.webiDetailImg}>
                      <StaticImage
                        src={"../../images/quiz/webinar1.png"}
                        placeholder={"blurred"}
                        alt="webinar1"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-12 ${styles.webDetailsCon}`}>
                <div className="row">
                  <div className={`col-8`}>
                    <div className={styles.title1}>Success stories</div>
                    <div className={styles.title2}>
                      of patients in real-time
                    </div>
                  </div>
                  <div className="col-4 d-flex justify-content-end align-items-end pe-0">
                    <div className={styles.webiDetailImg}>
                      <StaticImage
                        src={"../../images/quiz/webinar2.png"}
                        placeholder={"blurred"}
                        alt="webinar2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-12 ${styles.webDetailsCon}`}>
                <div className="row">
                  <div className={`col-8`}>
                    <div className={styles.title1}>1 : 1 Consultation</div>
                    <div className={styles.title2}>with diabetes expert</div>
                  </div>
                  <div className="col-4 d-flex justify-content-end align-items-end pe-0">
                    <div className={styles.webiDetailImg}>
                      <StaticImage
                        src={"../../images/quiz/webinar3.png"}
                        placeholder={"blurred"}
                        alt="webinar3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pb-5 d-none d-lg-block d-xl-block d-xxl-block">
          <div className="col-12 col-md-4 offset-md-4">
            <div className={`justify-content-center ${styles.btnCon}`}>
              <div
                role="presentation"
                onClick={navigateToWebinar}
                className="d-flex gradientBtnWithArrow pt-2 pb-2"
              >
                <span className={`pt-3 pb-3 ${styles.priceTxt}`}>
                  {" Register @ "}
                  <strike className={styles.strikRs}>
                    {" "}
                    &#2352;{" 500  "}
                  </strike>{" "}
                  &#2352;{" 79 "}
                </span>
                <span
                  className={`mediumIcon icon-arrow-forward ${styles.whiteFontArrow}`}
                />
              </div>
            </div>
            <div className="text-center">
              <span className={styles.Hurry}>Hurry Up! Limited seats</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizBookWebinar;
