import React, { Component } from "react";
import axios from "axios";
import queryString from "query-string";

import AppHeader from "../components/AppHeader/AppHeader";

// seo
import Seo from "../components/Seo";

// app header

// config
import config from "../config";

// Components

import QuizHome from "../components/DiabetesQuiz/QuizHome";
import DiabetesQuiz from "../components/DiabetesQuiz/DiabetesQuiz";
import QuizForm from "../components/DiabetesQuiz/QuizForm";
import ResultWaiting from "../components/DiabetesQuiz/ResultWaiting";
import QuizResult from "../components/DiabetesQuiz/QuizResult";
class DiabetesQuizPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      questionResult: {},
      quizResult: {},
      user: {},
    };
  }

  handleNextStep = nextStep => {
    this.setState({ step: nextStep });
  };

  submitQuiz = async values => {
    this.handleNextStep(2);

    const options = {
      method: "get",
      url: "https://dx-backend-prd.engg.twinsdigital.com/api/marketing/reversal",
      headers: {
        Authorization: `Bearer ${config.JWT_TOKEN}`,
      },
      params: values,
    };

    try {
      const res = await axios(options);
      const results = res.data.results;

      this.setState({
        quizResult: results[0] || {},
        questionResult: values,
      });

      setTimeout(() => {
        this.handleNextStep(3);
      }, 3000);
    } catch (error) {
      console.error("quiz error", error);
    }
  };

  handleUser = user => {
    this.setState({ user });
  };

  render() {
    const { step, questionResult, quizResult, user } = this.state;
    const { appHeader } = queryString.parse(this.props.location.search);

    return (
      <div>
        <Seo
          title="Diabetes Quiz | Diabetes Reversal Program | Twin"
          description="Diabetes Quiz. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
        />
        {appHeader === "mobile" && (
          <section>
            <AppHeader text="DIABETES QUIZ" isBack={true} />
          </section>
        )}

        <div>
          {step === 0 && (
            <QuizHome
              appForDxStatus={appHeader === "mobile"}
              handleNextStep={this.handleNextStep}
            />
          )}

          {step === 1 && (
            <DiabetesQuiz
              appForDxStatus={appHeader === "mobile"}
              handleNextStep={this.handleNextStep}
              submitQuiz={this.submitQuiz}
            />
          )}

          {step === 2 && (
            <ResultWaiting
              appForDxStatus={appHeader === "mobile"}
              handleNextStep={this.handleNextStep}
            />
          )}

          {step === 3 && (
            <QuizForm
              appForDxStatus={appHeader === "mobile"}
              handleNextStep={this.handleNextStep}
              handleUser={this.handleUser}
              questionResult={questionResult}
            />
          )}

          {step === 4 && (
            <>
              <QuizResult
                appForDxStatus={appHeader === "mobile"}
                handleNextStep={this.handleNextStep}
                quizResult={quizResult}
                user={user}
                questionResult={questionResult}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const withContext = props => <DiabetesQuizPage {...props} />;

export default withContext;
